import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "font-awesome/css/font-awesome.min.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Kathryn`, `Gutierrez`, `Hiester` ,`Spanish` ,`Interpreter` ,`Translator` ]} />
    <Helmet>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
        integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
        crossorigin="anonymous"
      />
      <meta name="google-site-verification" content="IOUBzFekeCOVOXLVxTlAmF8DHV_rbC8zd_7msryls44" />
    </Helmet>
    <article>
      <Card className="Card">
        <CardContent>
          <div className="media">
            <div className="img">
              <Image />
            </div>
            <h1 className="title">Kathryn Gutierrez Hiester, CMI</h1>
            <div className="content">
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.certifiedmedicalinterpreters.org/index.php?option=com_community&view=profile&userid=1000831449&module=view-all&uuid=view-all&current_page=93&directory_search_id=0"
                >
                  Certified Medical Interpreter
                </a>
              </p>
              <p>Spanish Medical &amp; Legal Interpreter</p>
              <p>
                Member{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://atanet.org/"
                >
                  ATA
                </a>
                ,
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.imiaweb.org/"
                >
                  {" "}
                  IMIA
                </a>
                ,
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.najit.org/"
                >
                  {" "}
                  NAJIT
                </a>
              </p>
              <div className="footer">
                <address>
                  <ul>
                    <li>
                      <i className="fas fa-envelope" />
                      <a href="mailto:spanishenglish@me.com">
                        spanishenglish@me.com
                      </a>
                    </li>
                    <li>
                      <i className="fab fa-linkedin" />
                      <a
                        target="_blank"
                        title="LinkedIn"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/kathrynhiester"
                      >
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                </address>{" "}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </article>
  </Layout>
)

export default IndexPage
